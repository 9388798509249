import { NgModule } from '@angular/core';
import {
    MatMenuModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatRadioModule,
    MatInputModule,
    MatRippleModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatExpansionModule,
    MatListModule,
    MatDialogModule,
    MatDatepickerModule,
    MatSliderModule,
    MatCardModule,
    MAT_DATE_LOCALE,
    DateAdapter,
    MatTabsModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatChipsModule,
    MatBottomSheetModule,
    MAT_DATE_FORMATS,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    MatButtonToggleModule,
    MatBadgeModule,
} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppDateAdapter, APP_DATE_FORMATS } from "../services/mask/datepicker";
import { SanitizeHtmlPipe } from '../services/sanitize/sanitize.pie';
@NgModule({
    imports: [
        MatMenuModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatRadioModule,
        MatInputModule,
        MatSnackBarModule,
        MatSidenavModule,
        MatExpansionModule,
        MatListModule,
        MatDialogModule,
        MatDatepickerModule,
        MatSliderModule,
        MatCardModule,
        MatTabsModule,
        MatToolbarModule,
        MatChipsModule,
        MatBottomSheetModule,
        MatProgressBarModule,
        MatStepperModule,
        MatProgressSpinnerModule,
        MatGridListModule,
        MatButtonToggleModule,
        MatRippleModule,
        MatBadgeModule,
        DragDropModule
    ],
    exports: [
        MatMenuModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatRadioModule,
        MatInputModule,
        MatSnackBarModule,
        MatSidenavModule,
        MatExpansionModule,
        MatListModule,
        MatDialogModule,
        MatDatepickerModule,
        MatSliderModule,
        MatCardModule,
        MatTabsModule,
        MatToolbarModule,
        MatChipsModule,
        MatBottomSheetModule,
        MatProgressBarModule,
        MatStepperModule,
        MatProgressSpinnerModule,
        MatGridListModule,
        MatButtonToggleModule,
        MatRippleModule,
        MatBadgeModule,

        SanitizeHtmlPipe,
        DragDropModule
    ],
    declarations: [SanitizeHtmlPipe],
    providers: [
        SanitizeHtmlPipe,
        {
            provide: DateAdapter, useClass: AppDateAdapter
        },
        {
            provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
        },
        { provide: MAT_DATE_LOCALE, useValue: 'vi-GB' },
    ]
})
export class MaterialModule { }